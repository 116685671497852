<template>
    <div>
        <Navbar />
        <div class="topo fale-topo">
            <div class="container">
                <h1>Fale Conosco</h1>
            </div>
        </div>
        <div class="conteudo fale-conosco">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <h1>Para solicitações</h1>
                        <p>compras, parcerias e outros assuntos, preencha o formulário abaixo:</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <!-- <form class="contact-form" @submit.prevent="sendEmail">
                            <div class="form-group">
                                <label for="name">Nome*</label>
                                <input type="text" v-model="name" name="name" class="form-control" required>
                            </div>
                            <div class="form-group">
                                <label>E-mail*</label>
                                <input type="email" v-model="email" name="email" class="form-control" required>
                            </div>
                            <div class="form-group">
                                <label>Assunto*</label>
                                <input type="text" v-model="assunto" name="assunto" class="form-control" required>
                            </div>
                            <div class="form-group">
                                <label>Mensagem*</label>
                                <textarea name="message" v-model="message" cols="30" rows="5" class="form-control" required></textarea>
                                <small id="emailHelp" class="form-text text-muted">*Campos obrigatórios</small>
                            </div>
                            <input type="submit" value="Enviar" class="btn btn-azul float-right">
                        </form> -->
                        <div class="form-rd">
                            <div role="main" id="contato-segmentado-goop-f6dcf30804434cedc1b6"></div>
                        </div>
                    </div>
                    <div class="offset-md-1 col-md-4">
                        <p class="p-mb">Telefone para contato</p>
                        <div class="box">
                            <img src="../assets/imgs/icons/phone.png" class="img-fluid">
                            <p>(11) 2634-1788</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- FOOTER -->
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import emailjs from 'emailjs-com';

export default {
    name: 'Fale-conosco',
    components: {
        Navbar,
        Footer
    },
    data() {
        return {
            name: '',
            email: '',
            assunto: '',
            message: ''
        }
    },
    methods: {
        sendEmail: (e) => {
            //emailjs.sendForm('service_h19pa8k', 'template_qxexy4k', e.target, 'user_RQd1mXEnMfV3G3GTuOWfR')
            emailjs.sendForm('service_id', 'template_id', e.target, 'user_id')
                .then((result) => {
                    console.log('SUCCESS!', result.status, result.text);
                    alert('email enviado');
                    location.reload();
                }, (error) => {
                    console.log('FAILED...', error);
                    alert('Formulário temporariamente indisponível');
                });
            }
        // sendEmail(e) {
        //   try {
        //     emailjs.sendForm('service_h19pa8k', 'template_qxexy4k', e.target, {
        //       name: this.name,
        //       email: this.email,
        //       assunto: this.assunto,
        //       message: this.message
        //     });

        //   } catch(error) {
        //       console.log({error})
        //   }
        // },
    },
    mounted() {
        if(window.RDStationForms) {
            new window.RDStationForms('contato-segmentado-goop-f6dcf30804434cedc1b6', 'UA-52442423-1').createForm();
        }
    }
}
</script>